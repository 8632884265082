import React from 'react'
import { useTable } from 'react-table'


import BTable from 'react-bootstrap/Table';


import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/projects.css";


function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <BTable striped bordered hover size="sm" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </BTable>
    )
}

function Projects() {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Title of Project',
                accessor: 'title', // accessor is the "key" in the data

            },
            {
                Header: 'Funding Agency',
                accessor: 'funding', // accessor is the "key" in the data
            },
            {
                Header: 'Duration',
                columns: [
                    {
                        Header: 'From',
                        accessor: 'from',
                    },
                    {
                        Header: 'To',
                        accessor: 'to',
                    }
                ],
            },
            {
                Header: 'Status',
                columns: [
                    {
                        Header: 'Ongoing/Completed',
                        accessor: 'status',
                    }
                ],
            },
            {
                Header: 'Amount',
                columns: [
                    {
                        Header: '(Rs)',
                        accessor: 'rs',
                    }
                ],
            }
        ],
        []
    )

    const data = React.useMemo(
        () => [
            {
                title: 'Resiliency and reliability of a renewable based power electronics based power system',
                funding: 'Ministry of Human Resource Development (MHRD) under SPARC Scheme',
                from: '15 March 2019',
                to: '15 March 2022',
                status: 'Ongoing',
                rs: '94.519 Lakhs'
            },
            {
                title: `Development of novel, efficient and cost effective power electronics based single phase system to convert Solar Energy from
            solar PV to Electric Energy`,
                funding: `Ministry of New &
            Renewable Energy,
            Govt of India
            `,
                from: '10 April 2014',
                to: '15 March 2017',
                status: 'Complete',
                rs: '106.31 Lakhs'
            },

            {
                title: `Rooftop Solar Grid Engineer`,
                funding: `National Institute of Solar Energy (NISE), Govt of India`,
                from: '14 Jan 2019',
                to: '24 Jan 2019',
                status: 'Complete',
                rs: '7.3 Lakhs'
            },
            {
                title: `Advances in Power Electronics & Renewable Energy Sources`,
                funding: `GIAN, MHRD`,
                from: '01 Feb 2017',
                to: '28 Feb 2017',
                status: 'Complete',
                rs: '5.44 Lakhs'
            },
        ],
        []
    )
    return (
        <div className="projects">
            <h1>R&D Projects</h1>
            <div className="table-container">
                <Table columns={columns} data={data} />
            </div>
        </div>
    )
}

export default Projects;
