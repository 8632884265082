import React from "react";

import { mediaGallery, mediaMagazine } from "../constants";

import "../styles/media.css";

const images = require.context("../images", true);

const Media = () => {
  return (
    <div className="media">
      <h1>Media </h1>
      <h2> Top Coverage by National and Internationa Media Houses </h2>
      <div className="media-gallery">
        {mediaGallery &&
          mediaGallery.map((item, index) => {
            let imgsrc = images(`./media${index + 1}.png`);
            return (
              <div className="gallery-item" key={index}>
                <img className="card-image" src={imgsrc} />
                <div className="title-container">
                  <h3 className="gallery-title">{item.title}</h3>
                </div>
              </div>
            );
          })}
      </div>

      <h2
        style={{
          fontSize: "1.8rem",
          color: "black",
          fontWeight: "600"
        }}
      >
        IEEE Magazine Coverage
      </h2>

      <div className="media-gallery">
        {mediaMagazine &&
          mediaMagazine.map((item, index) => {
            let imgsrc = images(`./${item.title}`);
            return (
              <div className="gallery-item" key={index}>
                <img className="card-image" src={imgsrc} />
                <div className="title-container"></div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Media;
