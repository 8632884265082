import React from 'react';

import { students } from "../constants";

import "../styles/students.css";

const images = require.context("../images", true);


function Students(props) {
    return (
        <div className="students">
            <h1> Alumni</h1>

            <div className="student-container">
                {
                    students.map(item => {
                        let imgsrc = images(`./${item.image}`);

                        return (
                            <>
                            <div className="member-container">
                                <img src={imgsrc} alt={item.name} className="member-img" />
                                <div className="member-details">
                                    <p className="member-name">{item.name}</p>
                                    <p className = "description">{item.title}</p>
                                    <p className = "description">{item.company}</p>
                                </div>
                             </div>
                            </>
                        )
                    })
                }
            </div>
        </div>

    );
}

export default Students;