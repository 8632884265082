import React from "react";
import Table from "react-bootstrap/Table";

import "../styles/publications.css";

import { publicationList } from "../constants/publications";


const publications = () => {
  return (
    <div className="publications">
      <h1>Publications</h1>
      <div className="table-container">
        <div>
          {
            publicationList.map((item) => {
              return (
                Object.keys(item).map(elem => {
                  const { body } = item[elem];
                  return (
                    <Table responsive="lg">
                      <thead>
                        <tr style={{
                          fontWeight: "bold",
                          margin: "0.5rem 0",
                          fontSize: "20px"
                        }}>
                          {elem}
                        </tr>
                        <tr>
                          {
                            item[elem].headings.map(elem => <th>{elem}</th>)
                          }
                        </tr>
                      </thead>
                      <tbody>

                        {
                          Object.keys(body).map((el, i) => {
                            const bodyItems = body[el];
                            return (
                              <tr>
                                {

                                  bodyItems.map(pointer => <td> {pointer}</td>)
                                }
                              </tr>
                            )

                          })
                        }
                      </tbody>
                    </Table>
                  )
                })
              )
            })
          }

        </div>

      </div>
      <a
        className="pub-link"
        href="https://scholar.google.co.in/citations?user=fxFp6KAAAAAJ&hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        View all Publications
      </a>
    </div>
  );
};

export default publications;
