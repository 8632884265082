import React from 'react';
import { labStats } from "../constants"

import IntrumentsList from "./instruments"

import ImgSrc from "../images/stats.jpeg"
import graphSrc from "../images/stats.png"

import { labFacilities } from '../constants';

import "../styles/facilities.css"

const images = require.context("../images", true);


function facilities() {
    return (
        <div className="research-container">
            <h1>Research Lab Setup</h1>
            <div className="main-container">

                <div className="left-container">
                    <div className="left-side-content">
                        <div className="faculty-banner">
                            <img src={ImgSrc} alt="" width="100%" height="100%" />
                        </div>
                        {labStats.main.map((item) => {
                            return (
                                <div className="stats-card">
                                    <div style={{
                                        width: "20%"
                                    }}>


                                        <div className="stats-num">
                                            {item.value}
                                        </div>
                                    </div>
                                    <div className="stats-title">
                                        {item.title}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="right">
                    {labStats.highlights.map((item) => {
                        return (
                            <div className="card-container">
                                <div
                                    style={{
                                        backgroundColor: item.color,
                                        color: "#f5f5f5"
                                    }}
                                    className="highlight-value">
                                    {item.value}
                                </div>
                                <p style={{
                                    marginTop: "0.5rem"
                                }}>{item.title}</p>
                            </div>
                        );
                    })}
                </div>

            </div>
            <img src={graphSrc} className="graph" alt="" />
            <div className ="section-facilities section">
                <h2 className ="heading-facilities"> State of Art & Testing Facilities </h2>
                <div style = {{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center"
                }}>
                    {labFacilities.map((facility) => {
                      const imgsrc = images(`./${facility.image}`);

                        return (
                            <div style = {{
                                height: "350px",
                                width: "350px"
                            }}
                            className="card-container"
                            >
                                <img src={imgsrc} alt="facility" className = "facility-img"></img>
                                <p style={{
                                    marginTop: "0.5rem"
                                }}>{facility.title}</p>
                            </div>
                        );
                    })}
                </div>

                <h2> List of state of the Instruments/Devices </h2>
                    <IntrumentsList/>

            </div>

        </div>
    );
}

export default facilities;