import React from "react";
import profileImg from "../images/profile2.jpeg";

import "../styles/about.css";
const about = () => {
  return (
    <div className="section">
      <p>About Us</p>
      <div className="about-us-body">
        <div className="about-us-text">
          Advance Power Electronics Research is established with the objective
          to comeout with innovative research based solution in the area of
          power electronics and its applications i.e. in renewable energy,
          electric vehicles, smart grids etc. The lab is established from the
          R&D grant received from Ministry of New and Renewable Energy, Govt of
          India, sanctioned to Dr. Ahteshamul Haque.Considering the fact that
          interdisciplinary nature of research work will lead to give better
          research outputs, the other areas of research is also incorporated in
          this lab i.e. image processing, Embedded System, Computer vision etc.
          This research lab has collaborations with various reputed national and
          International institutes/research groups. The few outcomes of this lab
          is, first awarded patent of the department is from this lab, research
          scholars working in this lab visited international laboratories under
          collaboration, industry experts visited this lab and get training in
          the area of solar inverter and Solar PV based plant design. Moreover,
          several research publication in reputed International journals is done
          from the work of this lab i.e. in IEEE, IET, Elsevier, Taylor &
          Francis, Springer, Wiley, Energy etc.
        </div>
        <div className="about-us-image">
          <img src={profileImg} alt = "Dr Ahteshamul Haque"></img>
          <p
            style={{
              fontWeight: "700"
            }}
          >
            {" "}
            Dr Ahteshamul Haque
          </p>
          <p> Founder & Lab Incharge</p>
        </div>
      </div>
    </div>
  );
};

export default about;
