import React, { Component } from "react";
import "../styles/home.css";
import { stats } from "../constants";
import logo from "../images/logo.png";


export default () => {
    return (
        <div className="home-container">
            <img src={logo} className = "logo"/>
            <h1>Advanced Power Electronics<br /> Research Lab </h1>
            <h2> Department Of Electrical Engineering, Jamia Millia Islamia, New Delhi</h2>
            <div className="highlights">
                {stats && stats.map(stat => {
                    const { title, content, description, border } = stat;
                    return (
                        <div className="card-item" style={{ borderBottomColor: `${border}`}}>
                            <h2 className="card-content">{content}</h2>
                            <p className="card-title">{title}</p>
                            <p className="card-description">{description}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}