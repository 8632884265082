import React from "react";
import { Link } from "react-router-dom";

import "../styles/research.css";
import "../App.css";

import item1 from "../images/item1.png";
import item2 from "../images/item2.png";
import item3 from "../images/item3.png";

import nise from "../images/nise.jpg";
import aalborg from "../images/aalborg.png";
import malaya from "../images/malaya.png";
import victoria from "../images/victoria.png";
import texas from "../images/texas.png";
import florida from "../images/florida.png";
import mnit from "../images/mnit.png";

const research = () => {
  return (
    <React.Fragment>
      <div className="section research">
        <div className="section-heading"> Research Areas</div>
        <p>We believing in empowering intelligence into the Industry</p>
        <div
          className="research-areas"
          style={{
            border: "none"
          }}
        >
          <div className="research-item">
            <div>
              <img src={item1} />
            </div>
            <div>Power Electronics & it's Applications</div>
            <p>
              Power Electronics and Applications, Renewable Energy, Reliability
              analysis of Power Electronics Converters
            </p>
          </div>

          <div className="research-item">
            <div>
              <img src={item2} />
            </div>
            <div>AI Based Control</div>
            <p>
              Using Artificial Intelligence to achieve better control over solar based inverters
            </p>
          </div>


          <div className="research-item">
            <div>
              <img src={item2} />
            </div>
            <div> Renewable Energy</div>
            <p>
              Renewable Energy with Solar Energy as the epicenter
            </p>
          </div>

          <div className="research-item">
            <div>
              <img src={item3} />
            </div>
            <div>Industry Automation</div>
            <p>
              {" "}
              Electric Vehicle, Digital Image and Digital Signal processing
            </p>
          </div>
        </div>
        <Link to="/publications">
          <h2 className="publication-link">View Publications</h2>
        </Link>
      </div>

      <div className="section research">
        <div> Research Collaborations</div>
        <p> National and International Collaborations</p>
        <div className="research-areas">

          <div className="research-item">
            <div>
              <img className="collab-logo" src={malaya} />{" "}
            </div>
            <div>Malaya University, Malaysia
            </div>
          </div>

          <div className="research-item">
            <div>
              <img className="collab-logo" src={victoria} />{" "}
            </div>
            <div>Victoria University, Australia
            </div>
          </div>

          <div className="research-item">
            <div>
              <img className="collab-logo" src={texas} />{" "}
            </div>
            <div>TEXAS A&M Univ, USA
            </div>
          </div>
          <div className="research-item">
            <div>
              <img className="collab-logo" src={florida} />{" "}
            </div>
            <div>University of West Florida, USA
            </div>
          </div>
          <div className="research-item">
            <div>
              <img className="collab-logo" src={mnit} />{" "}
            </div>
            <div>MNIT, Jaipur
            </div>
          </div>

          <div className="research-item">
            <div>
              <img className="collab-logo" src={nise} />
            </div>
            <div>National Institute of Solar Energy(NISE)</div>
            {/* <p>Government of India.</p> */}
          </div>

          <div className="research-item">
            <div>
              <img className="collab-logo" src={aalborg} />{" "}
            </div>
            <div>AALBORG University, Denmark</div>
            {/* <p>
              Ministry of Human Resource and Development Govt. of India (Under
              SPARC Scheme){" "}
            </p> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default research;
