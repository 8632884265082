import React, { Component } from "react";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';


import {sliderGallery} from "../constants";

import "../styles/gallery.css";
import "../App.css";


const images = require.context("../images", true);


class gallery extends Component {
  state = {};
  render() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    let imgArray = [];
        sliderGallery.map(item=>{
          let renderingImg = images(`./${item.name}`)
            imgArray.push(renderingImg);
        })
    

    return (
      <div className="section gallery">
        <div>Gallery </div>
        <div className="gallery-container">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={1000}
            bullets={false}
          >
            {imgArray && imgArray.map((item, i)=> {
              return (<div key ={i} data-src={`${item}`} />)
            })}
          </AutoplaySlider>
          
        </div>
      </div>
    );
  }
}

export default gallery;
