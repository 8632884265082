import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/header";
import Home from "./components/home";
import About from "./components/about";
import Research from "./components/research";
import Gallery from "./components/gallery";
import Contact from "./components/footer";
import Media from "./components/media";
import Publications from "./components/publications";
import OngoingResearch from "./components/ongoingResearch";
import Awards from "./components/awards";
import Facilities from "./components/facilities";
import Projects from "./components/projects.jsx";
import Students from "./components/students.jsx";
import ActiveStudents from "./components/ActiveStudents.jsx";


import 'bootstrap/dist/css/bootstrap.min.css';


import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Route exact path="/">
          <Home />
          <Gallery />
          <About />
          <Research />
          <Contact />
        </Route>
        <Switch>
          <Route path="/media">
            <Media />
          </Route>
        </Switch>

        <Switch>
          <Route path="/publications">
            <Publications />
          </Route>
        </Switch>

        <Switch>
          <Route path="/research">
            <OngoingResearch />
          </Route>
        </Switch>
        <Switch>
          <Route path="/project">
            <Projects />
          </Route>
        </Switch>
        <Switch>
          <Route path="/facilities">
            <Facilities />
          </Route>
        </Switch>
        <Switch>
          <Route path="/awards">
            <Awards />
          </Route>
        </Switch>
        <Switch>
          <Route path="/students">
            <Students />
          </Route>
          <Route path="/active-students">
          <ActiveStudents/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
