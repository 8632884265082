import React from 'react';

import { activeStudents } from "../constants";
import "../styles/activeStudents.css";

const images = require.context("../images", true);

function ActiveStudents() {
    return (
        <div className="active-students">
            <h1> Active Students </h1>
            <div>
                    {activeStudents && activeStudents.map((item, i) => {
                        const { students } = item;
                        return (
                        <div className ="container-main">
                                <h3 id="project-title">{i+1}. {item.projectTitle}</h3>
                            <div
                            className="project-container"
                            key={i}>
                            {
                            students && students.map(item => {
                            const imgSrc = images(`./${item.image}`)
                             return (
                                <>
                                <div className="member-container">
                                    <img src={imgSrc} alt={item.title} className="member-img" />
                                    <div className="member-details">
                                        <p className="member-name">{item.title}</p>
                                        <p className = "description">{item.description}</p>
                                        <p className = "description">{item.awards}</p>

                                    </div>
                                 </div>
                                </>
                                )
                             })
                            }
                            </div>
                        
                        </div>
                        )
                    })}

            </div>
        </div>
    );
}

export default ActiveStudents;