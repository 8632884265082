const stats = [
  {
    title: "Since",
    content: "2012",
    border: "hotpink",
    description: "Established in 2012, we have had exponential growth."
  },
  {
    title: "Publications",
    content: "100+",
    border: "#00bcd4",
    description:
      "In Worlds most reputed Journals and Conferences Like IEEE, IET, Elsevier, Wiley, Energy, Taylor & Francis, Springer"
  },
  {
    title: "Mentored",
    content: "1000+",
    border: "#ffbcd4",
    description:
      "In 8 years of operation at Jamia Millia Islamia, over 1000 students have been a part of our lab."
  },
  {
    title: "Patents",
    content: "06",
    border: "#FEC73D",
    description:
      "With 03 granted patent, and 03 filed, We keep progressing everyday."
  }
];

const labIncharge = [
  {
    name: "Sameen Ahmad",
    year: "2018"
  }
];
const UGStudents = [
  {
    name: "Some dude",
    year: "2016"
  }
];
const masterStudents = [
  {
    name: "lala",
    year: "idc"
  }
];
const docterateStudents = [
  {
    name: "woah",
    year: "frustating"
  }
];

const mediaGallery = [
  {
    title: "India Today"
  },
  {
    title: "The Week"
  },
  {
    title: "NDTV"
  },
  {
    title: "The Pioneer"
  },
  {
    title: "Okhla Times"
  },
  {
    title: "Education Times"
  },
  {
    title: "Times Of India"
  },
  {
    title: "Devdiscourse"
  },
  {
    title: "Business Standard"
  },
  {
    title: "Outlook"
  },
  {
    title: "The Indian Express"
  }
];

const mediaMagazine = [
  {
    title: "mag1.jpeg"
  },
  {
    title: "mag2.jpeg"
  },
  {
    title: "mag3.jpeg"
  },
  {
    title: "mag4.jpeg"
  }
];

const sliderGallery = [
  {
    name: "Pic 1.jpeg"
  },
  {
    name: "Pic 2.jpeg"
  }, 
  {
    name: "Pic 3.jpeg"
  }, 
  {
    name: "Pic 5.jpeg"
  }, 
  {
    name: "Pic 6.jpeg"
  },
  {
    name: "zoom1.jpeg"
  },
  {
    name: "zoom2.jpeg"
  },
  {
    name: "gallery-3.jpeg"
  },
  {
    name: "gallery-4.jpeg"
  },
  {
    name: "gallery-5.jpeg"
  },
  {
    name: "gallery-6.jpeg"
  },
  {
    name: "gallery-7.jpeg"
  },
  {
    name: "gallery-8.jpeg"
  },
  {
    name: "gallery0.jpeg"
  },
  {
    name: "gallery1.jpeg"
  },
  {
    name: "gallery2.jpg"
  },
  {
    name: "gallery3.jpeg"
  },
  {
    name: "gallery4.jpg"
  },
  {
    name: "gallery5.jpg"
  },
  {
    name: "gallery6.jpg"
  },
  {
    name: "gallery7.jpeg"
  },
  {
    name: "gallery8.jpeg"
  },
  {
    name: "gallery9.jpg"
  },
  {
    name: "gallery10.jpg"
  },
  {
    name: "gallery11.jpg"
  },
  {
    name: "gallery12.jpeg"
  },
  {
    name: "gallery13.jpg"
  },
  {
    name: "gallery14.jpeg"
  },
  {
    name: "gallery15.jpg"
  },
  {
    name: "gallery16.jpg"
  },
  {
    name: "gallery17.jpg"
  },
  {
    name: "gallery18.jpg"
  },
  {
    name: "gallery19.jpg"
  },
  {
    name: "gallery20.jpeg"
  },
  {
    name: "gallery21.jpg"
  },
  {
    name: "gallery22.jpg"
  },
  {
    name: "gallery23.jpeg"
  },
  {
    name: "gallery24.jpg"
  },
  {
    name: "gallery25.jpeg"
  },
  {
    name: "gallery26.jpeg"
  },
  {
    name: "gallery27.jpeg"
  },
  {
    name: "gallery28.jpeg"
  },
  {
    name: "gallery29.jpeg"
  },
  {
    name: "gallery30.jpeg"
  }
];

const CurrentResearch = {
  intro: `
  At JMI, pushing the boundaries of knowledge and possibility is our joyful obsession, and we celebrate fundamental discoveries and practical applications alike. As educators, we also value research as a potent form of learning by doing. Through JMI’s Research Opportunities, more than 85% of students enrich their education through engaging in frontline, faculty-led research.`,
  sections: [{
    title: "Artificial Intelligence based health monitoring of Solar Inverters",
    description: "",
    img: "gallery-3.jpeg"
  },
  {
    title: "Fault Tolerant Control for Multiple Converter based PV system",
    description: "",
    img: "fault-tolerant.jpeg"
  },
  {
    title: "Intelligent Monitoring of Solar Photovoltaic System",
    img: "intelligent-monitoring.jpeg",
    description: ""
  },
  {
    title: "Power Management of Single Phase Grid Connected Inverter",
    img: "power-management.jpeg",
    description: ""
  },
  {
    title: "Fast Charging of Electric Vehicle",
    img: "fast-charging.jpeg",
    description: ""
  },
  ]
}

const labStats = {
  main:
    [
      {
        title: "Number of PhD in progress",
        value: 5
      },
      {
        title: "Number of Completed M.Tech Dessertations",
        value: 27
      },
      {
        title: "Number of Awarded patents",
        value: 3
      },
      {
        title: "Number of Book Chapters Published",
        value: 10
      },
      {
        title: "Total Publications",
        value: 120
      },
      {
        title: "Publications in Web of Science Indexed SCI Journals",
        value: 28
      },
    ],
  highlights: [
    {
      title: "Number of Ph.D. Awarded",
      value: 5,
      color: "#DA6053",

    },
    {
      title: "No of Publications - SCOPUS Indexed",
      value: 78,
      color: "#00bcd4",
    },
    {
      title: "No of Publications Web of Science Indexed",
      value: 60,
      color: "#ffbcd4",
    },
    {
      title: "Number of Published Patents",
      value: 3,
      color: "#FEC73D",
    },
    {
      title: "Number of Books Published",
      value: 1,
      color: "#747474",
    },
  ]
}

const awardList = [
  {
    title: "International Award - 2019",
    image: "international19.jpeg"
  },
  {
    title: "IEEE Power and Energy Society Outstanding Engineer Award - 2020",
    image: "IEEEPower.png"
  },
  {
    title: "First Prize Hackathon - 2017",
    image: "hackathon17.png"
  },
  {
    title: "Best Paper Award - phD Chamber of commerce 2015",
    image: "bestpaper.png"
  }
]


const students = [
  {
    title: "Manager, TS-Transformer Workshop",
    name: "Tanweer Aadil",
    image: "tanweer.jpg",
    company: "Tata Power Delhi Distribution Ltd"
  },
  {
    title: "Senior Executive Engineer",
    name: "Javed Khan",
    image: "javed.jpg",
    company: "Siemens"

  }, {
    title: "Ph.D. Research Scholar",
    name: "Pavitra Shukl",
    image: "pavitra.jpeg",
    company: "IIT Delhi"

  }, {
    title: "Senior Executive - HVDC Component Engineering",
    name: "Altaf Sameen",
    image: "altaf.png",
    company: "Siemens"

  }, {
    title: "Research Scholar - Electrical Engineering Department",
    name: "Hina Parveen",
    image: "hina.jpeg",
    company: "IIT, Delhi"

  }, {
    title: "MS in Business Analytics",
    name: "Ayush Malani",
    image: "ayush.jpeg",
    company: "University of Texas, Austin"

  }, {
    title: "Senior Software Engineer",
    name: "Zahaib Mateen",
    image: "zahaib.jpeg",
    company: "Cisco Systems - San Jose, California"

  }, {
    title: "Team Lead - Aritificial Intelligence",
    name: "Sheena Siddiqui",
    image: "sheena.jpeg",
    company: "Accenture"

  }, {
    title: "Sr Engineer in Ground Transportation BU",
    name: "Afshan Siraj",
    image: "afshan.jpeg",
    company: "L&T Technology Services Ltd."

  }, {
    title: "Asst. Professor (Research)",
    name: "Dr. Himanshu Sharma",
    image: "himanshu.jpeg",
    company: "KIET Group of Institutions, Delhi-NCR"

  }, {
    title: "PhD Candidate",
    name: "Burhan Hyder",
    image: "burhan.jpeg",
    company: "Iowa State University"
  },
  {
    title: "Electrical Engineer",
    name: "Mohd. Shahzad",
    image: "shahzad.jpeg",
    company: "BSES Rajdhani Power Ltd"
  },
  {
    title: "JE",
    name: "Nasreen Khan",
    image: "nasreen.jpeg",
    company: "BSNL, Kolkata"
  },
  {
    title: "PhD Candidate",
    name: "Kanula Dadhich",
    image: "kanula.jpeg",
    company: "SVNIT, Surat"
  },
  {
    title: "Software Engineer",
    name: "Sameen Ahmad",
    image: "sameen.jpeg",
    company: "Pasarpolis India"
  }

]

const labFacilities = [
  {
    title: "Solar PV Simulator",
    image: "facility1.png",
  },
  {
    title: "High End LCR Meter",
    image: "facility2.png",
  },
]

const InstrumentsList = [
  {
    title: "Teledyne Lecroy HRO 64Zi",
    chars: [
      "Manufacturer: Teledyne LeCroy",
      "Oscilloscopeswith PC",
      "Number of Channels: 4 Channel",
      "Bandwidth: 400MHz",
      "Sampling Rate: 2 GS/s",
      "Display Type: Color WVGA",
      "Input Voltage: 5V to 400V"
    ],
    image: "lecroy.png"
  },
  {
    title: "Le Croy AP015 Current Probe",
    chars: [
      "30 Arms continuous current, 50 MHz bandwidth",
      "50A peak current for upto 10 seconds",
      "50 MHz Bandwidth",
      "7ns Rise time",
      "Overheating and probe unlock detection"
    ],
    image: "currentprobe.png"
  },

  {
    title: "ADP305 Voltage Probe",
    chars: [
      "100MHz Bandwidth",
      "1000 VRMS common modle voltage 1400Vpeak differential voltage",
      "EN61010 Cat III",
      "80dB CMRR at 50/60Hz",
      "ProBus System",
      "Full remote control",
      "Compatible with LC Series, LT Series,WS 400 Series, WS Xs Series, WR 6000(A) Series, WR Xi Series, WP 900 Series, WP7000(A) Series"
    ],
    image: "voltageprobe.png"
  },
  {
    title: "PVC Inverter Stack, 750 Volt DC",
    chars: [
      "Current: 35Amp",
      "Material: PVC",
      "Output Voltage: 750 Volt DC",
      "Brand: VP Electronics",
      "Capacity: 1-50kW",
      "Input Voltage: 230-415V",
      "Phase: Three-Phase",
      "Frequency Range: 50/60Hz"
    ],
    image: "inverterstack.png"
  },
  {
    title: "Typhoon HIL 402",
    chars: [
      "2 x three-phase converters",
      "1 x three-phase rectifier",
      "1 x induction machine with squirrel cage",
      "Detailed primary control in signal processing"
    ],
    image: "typhoon.png"

  },
  {
    title: "Yokogawa WT310E",
    chars: [
      "Bandwidth: DC and 0.1Hz to 100KHz (WT310EH is upto 20kHz for 40A).",
      "Direct Input: Maximum direct input of upto 600V and 20A, or 40A (incase of WT310EH)",
      "Simultaeneous Measurement: U/I/P/Freq/Integ(+/-) Harmonic Components/ THD with max 100ms cycle."
    ],
    image: "yogogawa.png"

  }
  ,
  {
    title: "Sbio Inverter Control Board",
    chars: [
      "National Intrument, Used for Control with NI Lab Software"
    ],
    image: "controlerboard.png"

  },
  {
    title: "Solar Panel of 1KW",
    chars: [
      "Lab is getting power from 1KW solar panel, Make: ELDORA"
    ],
    image: "solarpanel.png"

  },
  {
    title: "Simulation Software",
    chars: [
      "PSIM"
    ],

  },
  {
    title: "Hardware in Loop Software",
    chars: [
      "Lab View"
    ],
  },
  {
    title: "Real Time Simulation Software",
    chars: [
      "Typhoon HIL"
    ],
  }
]

const activeStudents = [
  {
    students: [{
      title: "Azra Malik",
      image: "azra.jpeg",
      description: "Prime Minister Research Fellow",
      awards: "Jamia Excellence award winner, August'21",
    }],
    projectTitle: "Fault Tolerant Converter for Grid-connected Photovoltaic System",
    projectDesc: "The objective of this research is to develop a parallel fault tolerant grid-connected Photovoltaic (PV) inverter system, which will help in enhancing the reliability and maintain continuous supply during the undesirable/faulty conditions. This will drastically enhance the reliability and availability of the system"
  }, 
  {
    students: [{
      title: "Izhar Ahmad Saifi",
      image: "izharahmad.jpeg",
    }],
    projectTitle: "Control of Microgrid system with Renewable Energy Integration"
  },
  {
    students: [
      {
        title: "Md Touqir Hussain",
        image: "touquirhussain.jpeg"
      }
    ],
    projectTitle: "Roboust decision making for dynamic voltage support in grid connected PV system"
  },
  {
    students: [
      {
        title: "Tausif Khan",
        image: "tausifkhan.jpeg",
      },
      {
        title: "Rasikh Asad",
        image: "rasikh.jpeg",
      },
      {
        title: "Hammad Ashraf Ansari",
        image: "hammadashraf.jpeg",
      }
    ],
    projectTitle: "IOT based monitoring of Power Electronic Converter for Electric Vehicles"
  },

  {
    students: [
      {
        title: "Afra Naushad",
        image: "afra.jpeg"
      },
      {
      title: "Rufaida Khan",
      image: "rufaida.jpeg"
    },
   
    {
      title: "Iftekhar Ansari",
      image: "iftekhar.jpeg"
    },
    {
      title: "Shadab Faisal",
      image: "shadabfaisal.jpeg"
    },
    ],
    projectTitle: "Artificial intelligence based control of grid connected inverters"
  }
]

const Members = {
  labIncharge,
  docterateStudents,
  masterStudents,
  UGStudents,
  sliderGallery,
  mediaMagazine
};

export { stats, Members, mediaGallery, sliderGallery, mediaMagazine, CurrentResearch, labStats, awardList, students, labFacilities, InstrumentsList, activeStudents };
