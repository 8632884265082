import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { awardList } from "../constants";

import "../styles/awards.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"

const images = require.context("../images", true);


function Awards(props) {
    return (
        <div className="awards">
            <h1>Awards & Achievements From the Lab </h1>
            <div className="carousel-container">
                <Carousel>
                    {awardList.map((item, index) => {
                        let imgsrc = images(`./${item.image}`);

                        return (
                            <div key={index}>
                                <img src={imgsrc} alt="" />
                                <p className="legend">{item.title}</p>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </div>
    );
}

export default Awards;