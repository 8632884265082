import React from 'react';
import { CurrentResearch } from "../constants";

import "../styles/ongoingResearch.css";

const images = require.context("../images", true);

const ongoingResearch = () => {
    return (
        <div className="section-research">
            <h1 className="research-heading"> Ongoing Research Work </h1>
            <h2 className="intro">{CurrentResearch.intro}</h2>
            {CurrentResearch.sections.map((item) => {
                let imgsrc = images(`./${item.img}`);
                return (
                    <div className="section-item">
                        <div className="section-child-item">
                            <h2> {item.title}</h2>
                            <p>{item.description}</p>
                        </div>
                        <img src={imgsrc} alt="" />
                    </div>
                )
            })

            }
        </div>
    );
}

export default ongoingResearch;