const publicationList = [
    {
        "Patents": {
            headings: [
                "S.No.",
                "Title",
                "Inventors",
                "Status- Number/Filed/Published Awarded",
                "Date of Filing/Award",
                "National/International"
            ],
            body: {
                1: [
                    "1",
                    "Artificial Intelligence Enabled Health Monitoring System for Grid Connected Solar Inverter",
                    "Ahteshamul Haque, KVS Bharath, Mohammed Ali Khan, Rajesh Kumar",
                    "Indian Patent No. 202011038582/DEL/2020 Published",
                    "August 2020",
                    "India, National"
                ],
                2: [
                    "2",
                    "System for Energy Conversion Including A Bidirectional DC-DC Converter",
                    "Ahteshamul Haque, Sheena Siddiqui, Azra Malik, Md. Danish Zunnoon",
                    "India Patent No. 332187 Awarded",
                    "Feb 2020",
                    "India, National"
                ],
                3: [
                    "3",
                    "Solar Energy System Based Power Management In Wireless Sensor Nodes For Smart Agricultural Control And Monitoring",
                    "Himanshu Sharma, Ahteshamul Haque",
                    "India Patent No. 201911025137/ DEL/2019 Published",
                    "July 2019",
                    "India, National"
                ],
                4: [
                    "4",
                    "Circuit for Metal Halide HID Lamps",
                    "Ahteshamul Haque, Ammar Rafiq, Munshareh Shafaq. Altaf Sameen, Hina Parveen",
                    "India Patent No. 7594/DEL/2016 Published",
                    "Sept 2016",
                    "India, National"
                ],
                5: [
                    "5",
                    "Ballast with circuit for detecting and eliminating an unwanted arc condition",
                    "Ahteshamul Haque",
                    "Europe Patent No. EP1742517 Awarded",
                    "March 2008",
                    "Europe , International"
                ],
                6: [
                    "6",
                    "Ballast with Circuit for detecting and eliminating an arc condition",
                    "Ahteshamul Haque",
                    "USA Patent No. 7183721 Awarded",
                    "Feb 2007",
                    "USA, International"
                ],
            }
        }
    },
    {
        "Book": {
            headings: [
                "S.No.",
                "Title",
                "Editor",
                "ISBN No",
                "Date",
                "Indexing"
            ],
            body: {
                1: [
                    "1. ",
                    "Reliability of Power Electronics Converters for Solar Photovoltaic Applications,Published by IET",
                    "Ahteshamul Haque, Frede Blaabjerg, Huai Wang, Yongheng Yang",
                    "978-1-83953-116-3",
                    "June 2021",
                    "SCOPUS"
                ]
            }
        }
    },
    {
        "Chapters in Book": {
            headings: [
                "S.No.",
                "Title",
                "Editor",
                "Book Title ISBN No",
                "Date",
                "Indexing"
            ],
            body: {
                1: [
                    "1",
                    "Power electronics converter for solar PV applications",
                    "Ahteshamul Haque, Frede Blaabjerg, Huai Wang, Yongheng Yang",
                    "IET Book: “Reliability of Power Electronics Converters for Solar Photovoltaic Applications” ISBN: 978-1-83953-116-3",
                    "June  2021",
                    "SCOPUS"
                ],
                2: [
                    "2",
                    "Centralized Fault Localization Approach for Islanded Three Phase Microgrid Systems",
                    "R N Shaw, Saad Mekhilef",
                    "Application of AI and IoT in Renewable Energy, Published by Elsevier, ISBN No. 9780323916998",
                    "Sept 2021",
                    "SCOPUS"
                ],
                3: [
                    "3",
                    "Priority Based Power Delivery System for Electric Vehicle Charging",
                    "Abdalmuttaleb M.A Musleh Al-Sartawi Anjum Razzaque",
                    "Springer Book “Artificial Intelligence Systems and the Internet of things in the Digital Era” ISBN: 978-3-030-77246-8",
                    "June 2021",
                    "SCOPUS"
                ],
                4: [
                    "4",
                    "Transfer Learning Based Novel Fault Classification Technique for Grid Connected PV Inverter",
                    "Saad Mekhilef, M Favorskaya, R K Pandey, R N Shaw",
                    "Springer Book Series “Innovations in Electrical & Electronics Engineering ISBN 978-981-16-0748-6",
                    "May 2021",
                    "SCOPUS"
                ]
                ,
                5: [
                    "5",
                    "Intelligent Control of Converter for Electric Vehicle Charging Station",
                    "G Carpinelli, P D Falco, F Motolla.",
                    "MDPI Book 'Distributed Energy Storage Devices in Smart Grids' ISBN 978-3-03928-434-4",
                    "April 2020",
                    "SCOPUS"
                ],
                6: [
                    "6",
                    "Fault Detection in Single-Phase Inverters Using Wavelet Transform-Based Feature Extraction and Classification Techniques",
                    "Sukumar Mishra, Yog Raj Sood, Anuradha Tomar",
                    `Springer Book Series “Applications of Computing, Automation and Wireless Systems in Electrical Engineering” ISBN: 978-981-13-6772-4`,
                    "April 2020",
                    "SCOPUS"
                ],
                7: [
                    "7",
                    "Voltage-Balancing Control for Stand-Alone H5 Transformerless Inverters",
                    "Sukumar Mishra, Yog Raj Sood, Anuradha Tomar",
                    `Springer Book Series “Applications of Computing, Automation and Wireless Systems in Electrical Engineering” ISBN: 978-981-13-6772-4`,
                    "April 2020",
                    "SCOPUS"
                ],
                8: [
                    "8",
                    "Modeling and optimisation of a solar energy harvesting system for wireless sensor network nodes",
                    "B Kantarki, S Oktug",
                    `MDPI Book “Wireless Sensor and Actuator Networks for Smart Cities” ISBN 978-3-03897-423-9`,
                    "March 2019",
                    "SCOPUS"
                ],
                9: [
                    "9",
                    "Solar Energy",
                    "M. H. Rashid",
                    `Elsevier Book 'Electric Renewable Energy Systems' ISBN: 978-0-12-804448-3`,
                    "Dec 2015",
                    "SCOPUS"
                ],
                10: [
                    "10",
                    "AC-DC Converter",
                    "M. H. Rashid",
                    `Elsevier Book “Electric Renewable Energy Systems” ISBN: 978-0-12-804448-3`,
                    "Dec 2015",
                    "SCOPUS"
                ]
            }
        }
    },

    {
        "Referred General": {
            headings: [
                "S.No.",
                "Authors, Title, Publisher, Date",
                "Indexing, Impact Factor/Cite Score"
            ],
            body: {
                1: ["1.",
                    "Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Dynamic Voltage Support for Low Voltage Ride Through Operation in Single-Phase Grid-Connected Photovoltaic Systems” IEEE Transactions on Power Electronics, Accepted in April 2021, vol. 36, issue 10, Published in Oct 2021 Issue, pp.no. 12102-12111.",
                    "SCI, 6.373 / 14.5"
                ],
                2: [
                    "2.",
                    "Mohammad Jasim Usmani, Ahteshamul Haque, “Power Management of Solar PV systems for PEER load” IEEE Transactions of Industry Applications, Accepted in July 2021, Early Access DOI: 10.1109/TIA.2021.3100809",
                    "SCI, 3.654/8.9"
                ],
                3: [
                    "3. ",
                    "Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, Huai Wang, Frede Blaabjerg, “Standalone operation of distributed generation systems with improved harmonic elimination scheme” IEEE Journal of Emerging and Selected Topics in Power Electronics, Accepted in May 2021, 10.1109/JESTPE.2021.3084737",
                    "SCI, 4.728 /11.3"
                ],
                4: [
                    "4. ",
                    "Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, Mekhilef Saad, “Islanding Classification Mechanism for Grid-Connected Photovoltaic System” IEEE Journal of Emerging and Selected Topics in Power Electronics, Accepted in April 2020, Published Vol 9, No.2, April 2021",
                    "SCI, 4.728 /11.3"
                ],

                5: [
                    "5. ",
                    "Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, Mekhilef Saad, “Advance Control Strategy with Voltage sag classification for Single Phase Grid Connected Photovoltaic System” IEEE Journal of Emerging and Selected Topic in Industrial Electronics. Accepted in Dec 2020. 10.1109/JESTIE.2020.3041704",
                    "SCI, 7.515/16.4*"
                ],
                6: [
                    "6. ",
                    "K V S Bharath, Ahteshamul Haque, P S Kumar, Mohammed Ali Khan “Rule based Inferential System For Microgrid Energy Management System” IEEE Systems Journal, Accepted in July 2021, 10.1109/JSYST.2021.3094403",
                    "SCI, 3.931/ 7.7"
                ],
                7: [
                    "7. ",
                    "Ahteshamul Haque, K V S Bharath, Mohammed Ali Khan,    “Stochastic methods for prediction of charging and Discharging Power of Electric Vehicles in Vehicle to Grid Environment” IET Journal of Power Electronics, Vol. 12, issue.13, pp. 3510-3520, Sept 2019.",
                    "SCI, 2.672/5.5"
                ],
                8: [
                    "8. ",
                    "K V S Bharath, Ahteshamul Haque, Mohammed Ali Khan, Subham Sahoo, Azra Malik, Frede Blaabjerg, “A Review on Artificial Intelligence Applications for Grid Connected Solar Photovoltaic Systems” MDPI Journal of Energies, August 2021, 14(15), 4690 , https://doi.org/10.3390/en14154690",
                    "SCI, 3.004/4.7"
                ],
                9: [
                    "9. ",
                    "K V S Bharath, Ahteshamul Haque, Arun Kumar Tripathi, Mohammed Ali Khan, “Condition Monitoring of IGBT modules using online TSEPs and data-driven approach” Wiley International Transaction on Electrical Energy Systems, Accepted in May 2021, https://doi.org/10.1002/2050-7038.12969",
                    "SCI,  2.86/3.13"
                ], 10: [
                    "10. ",
                    "Mohammed Ali Khan, Ahteshamul Haque, Frede Blaabjerg, K V S Bharath, Huai Wang, “Intelligent Transition Control between Grid Connected and Standalone modes of Three phase Grid Integrated Distributed Generation Systems” MDPI Journal of Energies, July 2021, 14(13), 3979; https://doi.org/10.3390/en14133979 ",
                    "SCI 3.004/ 4.7"
                ], 11: [
                    "11. ",
                    "Zoya Fatama, Ahteshamul Haque, K VS Bharath, Mohammed Ali Khan, Frede Blaabjerg, “Coordinated reactive power strategy using static synchronous compensator for Photovoltaic Inverters” Wiley International Transaction on Electrical Energy Systems, Accepted in Feb 2020, DOI: 10.1002/2050-7038.12393, published March 2020, pp. 1-18.",
                    "SCI,  1.692/2.7"
                ], 12: [
                    "12. ",
                    "Himanshu Sharma, Ahteshamul Haque, Frede Blaabjerg, “Machine learning in wireless sensor networks for smart cities: A survey” MDPI Journal of Electronics, Vol. 10, 10 (09), April 2021. https://doi.org/10.3390/electronics10091012",
                    "SCI, 2.412/1.9"
                ], 13: [
                    "13. ",
                    "K V S Bharath, Ahteshamul Haque Frede Blaabjerg, Mohammed Ali Khan, “A Novel Fault Classification Approach for Photovoltaic Sysems” MDPI Journal of Energies, 2020, 13, 308, pp. 1-17, Jan 2020.",
                    "SCI, 2.702/3.8"
                ], 14: [
                    "14. ",
                    "Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Intelligent Control of a novel Transformerless inverter topology for photovoltaic applications” Springer Journal of Electrical Engineering, Vol. 102, pp. 627-641, Dec, 2019. DOI: 10.1007/s000202-019-00899-2.",
                    "SCI, 1.18/2.3"
                ], 15: [
                    "15. ",
                    "Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Performance assessment of Standalone Transformerless inverter”, Wiley International Transaction of Electrical Energy systems, pp.1-20, DOI: 10.1002/2050-7038.12156, Aug 2019",
                    "SCI, 1.692/2.7"
                ], 16: [
                    "16. ",
                    "KVS Bharath, Frede Blaabjerg, Ahteshamul Haque,  M A Khan, “Model-Based Data Driven Approach for Fault Identification in Proton Exchange Membrane Fuel Cell”, MDPI Journal of Energies, Vol. 13, issue. 12, pp. 3144, June 2020.",
                    "SCI, 2.702/3.8"
                ], 17: [
                    "17. ",
                    "Ahteshamul Haque, AA Al-Shareef, Asif Irshad Khan, Md. Mottahir Alam, KVS Bharath, Kashif Irshad, “Data Description Technique-Based Islanding Classification for Single-Phase Grid-Connected Photovoltaic System” MDPI Journal of Sensors, Vol. 20, issue. 11, pp. 3320, July 2020",
                    "SCI,  3.275/5"
                ], 18: [
                    "18. ",
                    "Himanshu Sharma, Ahteshamul Haque, Z A Jaffery, “Maximization of wireless sensor network lifetime using solar energy harvesting for smart agriculture monitoring”, Elsevier Journal of Adhoc Networks, Vol. 94, Nov 2019, https://doi.org/10.1016/j.adhoc.2019.101966",
                    "SCI, 3.643/7.8"
                ], 19: [
                    "19. ",
                    "M Jha, Frede Blaabjerg, M A Khan, KVS Bharath, Ahteshamul Haque, “Intelligent Control of Converter for Electric Vehicles Charging Station”, MDPI Journal of Energies, Vol. 12, pp. 1-25, June 2019, https://doi.org/10.3390/en12122334.",
                    "SCI, 2.702/3.8"
                ], 20: [
                    "20. ",
                    "Ahteshamul Haque, K V S Bharath, Mohammed Ali Khan, Irshad, Zainul Abdin Jaffery, “Fault Diagnosis of Photovoltaic Modules” Published in Wiley Energy Science & Engineering, Vol 7, issue 3, pp. 622-644, March 2019. ",
                    "SCI 2.631/2.3"
                ], 21: [
                    "21. ",
                    "Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, Saad Mekhilef, “Single Phase Transformerless Photovoltaic Inverter for Grid Connected Systems- AN Overview” Inderscience International Journal of Power Electronics, Oct 2018, 10.1504/IJPELEC.2021.10020079",
                    "SCOPUS, 1.0/1.0"
                ], 22: [
                    "22. ",
                    "Mohammed Ali Khan, S Mishra, Ahteshamul Haque, “A present and future state of the art development for energy efficient buildings using PV systems” Taylor Francis Journal of Intelligent Building International, March 2018, pp. 44-63, ISSN No. 1750-8975.",
                    "SCOPUS,  2.4/2.4"
                ], 23: [
                    "23. ",
                    "H Sharma, Ahteshamul Haque, Z. A. Jaffery, “Solar Energy Harvesting Wireless Sensor network nodes: A Survey” Journal of Renewable and Sustainable Energy, March 2018, PP. 01-25, ISSN No. 1941-7012 ",
                    "SCI, 1.611/3.2"
                ], 24: [
                    "24. ",
                    "H Sharma, Ahteshamul Haque, Z A Jaffery, “Modelling and Optimization of a Solar Energy Harvesting System for Wireless Sensor Network Nodes” MDPI Journal of Sensors and Actuator Networks, Vol. 7, issue 3, Sept 2018.",
                    "SCI, 4.2/4.2"
                ], 25: [
                    "25. ",
                    "Irshad, Z A Jaffery, Ahteshamul Haque “Temperature measurement of Solar Module in outdoor operating conditions using thermal imaging” Elsevier Journal of Infrared Physics and Technology, Vol 92, pp. 134-138, May 2018.",
                    "SCI 2.379/4.0"
                ], 26: [
                    "26. ",
                    "Ahteshamul Haque, Z. A. Jaffery, Irshad, A K Dubey, “ Scheme for predictive fault diagnosis in photovoltaic modules using thermal imaging”, Elsevier Journal of Infrared Physics & Technology, vol.83, pp. 182-187, May 2017",
                    "SCI 2.379/4.0"
                ], 27: [
                    "27. ",
                    "Zaheeruddin, Sukumar Mishra, Ahteshamul Haque, “ Performance Evaluation of modified perturb & observe maximum power point tracker for Solar PV System”, Springer – Int J of System Assurance Engineering Management, pp. 1-12, June 2015. (ISSN- 0975-6809)",
                    "SCI 1.02 /2.4"
                ], 28: [
                    "28. ",
                    "Ahteshamul Haque, Zaheeruddin, “A fast and reliable perturb and observe maximum power point tracker for solar PV system” Springer – Int J of System Assurance Engineering Management, pp. 1-17, Aug 2016. ISSN NO. 0975-6809.",
                    "SCI 1.02 /2.4"
                ], 29: [
                    "29. ",
                    "Zaheeruddin, Sukumar Mishra, Ahteshamul Haque , “Operational Characteristics of DC-DC converters in maximum power point tracking operation for Solar PV system” International Journal of Applied Engineering Research, Vol. 10, No. 6, pp. 15083-15090, 2015 (ISSN-0973-4562)",
                    "SCOPUS,  1.0"
                ], 30: [
                    "30. ",
                    "Himanshu Sharma, Ahteshamul Haque, Z A Jaffery, “Smart Agriculture   Monitoring using Energy Harvesting Internet of Things (IoT)”, Journal of    World Scientific News, pp. 22-26, March 2019.",
                    "Cite Factor, Google Scholar"
                ],
                31: [
                    "31. ",
                    "Ahteshamul Haque “Maximum Power Point Tracking (MPPT) for Scheme for Solar Photovoltaic System” Taylor and Francis Journal of Energy Technology and Policy, 2014, pp. 115-122. ISSN- 2331-7000.",
                    "Cite Factor, Google Scholar"
                ],
                32: [
                    "32. ",
                    "Ahteshamul Haque, “Power Quality of Electronic Control System for Metal Halide HID Lamps” International Journal of Science Technology & Engineering, Vol 2, Issue 08, Feb 2016. ISSN – 2349-784X.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 33: [
                    "33. ",
                    "Ahteshamul Haque, “Performance Evaluation of Maximum Power Point Tracking Algorithm with Boost DC-DC Converter for Solar PV System” International Journal of Science Technology & Engineering, Vol 2, Issue 08, Feb 2016. ISSN – 2349-784X.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 34: [
                    "34. ",
                    "Ahteshamul Haque, “ Design and Development of Perturb & Observe MPPT Technique for Solar PV based Energy Conversion System”, International Advanced Research Journal in Science, Engineering & Technology, Vol.3, Iss.2, Feb 2016. ISSN No. 2394-1588.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 35: [
                    "35. ",
                    "Ahteshamul Haque, “ Analysis of Electronic Control System of CFL Lamp”, International Journal of Innovative Research in Science & Technology, Vol2, Issue 9, 2016. ISSN No. 2349-6010. ",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 36: [
                    "36. ",
                    "Ahteshamul Haque, “Valley Fill Circuit for Power Quality Improvement”, International Journal of Innovative Research in Science & Technology, Vol2, Issue 9, 2016. ISSN No. 2349-6010.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 37: [
                    "37. ",
                    "Ahteshamul Haque, “Solar PV Energy Conversion system and its Configurations” International Journal of Engineering Research and Applications, Vol. 06, Iss. 02, Feb 2016, pp. 80-84.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 38: [
                    "38. ",
                    "Ahteshamul Haque, “Performance evaluation of maximum powerpoint tracking algorithm with buck dc-dc converter for solar PV system” International Journal of Engineering Research and Applications, Vol. 06, Iss. 02, Feb 2016, pp. 76-79.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ],
                39: [
                    "39. ",
                    "Ahteshamul Haque, “Performance Evaluation of Maximum Power Point Tracking Algorithm with Buck –Boost DC-DC Converter for Solar PV System” International Journal of Science Technology & Engineering, Vol 2, Issue 08, 2016. ISSN – 2349-784X.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ],
                40: [
                    "40. ",
                    "Ahteshamul Haque, “ Operation and Control of Bidirectional DC-DC Converter for HEV”, International Journal of latest Engineering Research and Application, Vol. 02, Issue. 10, Oct 2017, pp. 30-37. ISSN No.2455-7137.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 41: [
                    "41. ",
                    "Ahteshamul Haque, “Efficiency of Photovoltaic System for DC Loads”, International Journal of latest Engineering Research and Application, Vol. 02, Issue. 10, Oct 2017, pp. 38-45. ISSN No.2455-7137",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ]
                , 42: [
                    "42. ",
                    "Ahteshamul Haque, “Load variation effect on Maximum Power Point Tracker (MPPT) for Solar Photovoltaic (PV) Energy Conversion system ”, International Journal of Modern trends in  Engineering and Research, Vol. 04, Issue. 10, Oct 2017, pp. 38-46,  ISSN No.2349-9745.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ], 43: [
                    "43. ",
                    "Ahteshamul Haque, “A simplified control strategy for Bi directional DC-DC converter for DC microgrid application ”, International Journal of Modern trends in  Engineering and Research, Vol. 04, Issue. 10, Oct 2017,PP. 14-23, ISSN No.2349-9745.",
                    "Peer reviewed, Cite Factor, Google Scholar"
                ]

            }
        }
    },
    {
        "Conference/Workshop/Symposium Proceedings": {
            headings: [
                "S.No.",
                "Authors, Title, Publisher, Date",
                "Indexing"
            ],
            body: {
                1: ["1. ",
                    "Adnan Mian, Ahteshamul Haque, K V S Bharath, “Reliability Improvement of Power Devices for Electric Vehicle Traction” Accepted in June 2021, IEEE International Conference on Computing, Power and Communication Technologies, Malaysia.",
                    "SCOPUS"],
                2: ["2. ",
                    "Mohammed Amir, Zaheeruddin, Ahteshamul Haque, “Integration of EVs Aggregator with Microgrid and Impact of V2G Power on Peak Regulation” Accepted in June 2021, IEEE International Conference on Computing, Power and Communication Technologies, Malaysia",
                    "SCOPUS"],
                3: ["3. ",
                    "Azra Malik, Ahteshamul Haque, K V S Bharath, “Deep Learning Based Fault Diagnostic Technique for Grid Connected Inverter” IEEE Conference on Energy Conversion Conference Exposition ECCE- Asia 2021, May 2021.",
                    "SCOPUS"],
                4: ["4. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Intelligent Transition Control Approach for Different Operating Modes of Photovoltaic Inverter” IEEE Conference on Energy Conversion Conference Exposition ECCE- Asia 2021, May 2021.`,
                    "SCOPUS"]
                ,
                5: ["5. ",
                    `Azra Malik, Ahteshamul Haque, K VS Bharath, “Transfer Learning Based Novel Fault Classification Technique for Grid Connected PV Inverter”, Springer International Conference on Electrical and Electronics Engineering (ICEEE-2021) 02-03 January 2021.`,
                    "SCOPUS"]
                ,
                6: ["6. ",
                    `Noaima Bari, Ahteshamul Haque, Gaurav Ahuja, K V S Bharath, “Priority Based Power Delivery System For Electric Vehicle Charging” Springer Conference European, Asian. Middle Eastern, North African Conference on Management and Information Systems (EAMMIS)- 2021, March 2021. `,
                    "SCOPUS"]
                ,
                7: ["7. ",
                    `K V S Bharath, Ahteshamul Haque, Rajesh Kumar, Mohammed Ali Khan, A K Tripathi, “Machine Learning Based Fault Classification Approach for Power Electronic Converters” IEEE International Conference on Power Electronics, Drives and Energy Systems (PEDES-2020), Dec 2020 `,
                    "SCOPUS"]
                ,
                8: ["8. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Reliability Analysis of a Solar Inverter during Reactive Power Injection” IEEE International Conference on Power Electronics, Drives and Energy Systems (PEDES-2020), Dec 2020`,
                    "SCOPUS"]
                ,
                9: ["9. ",
                    `Jaseem Usmani, Ahteshamul Haque, “Power Management of Solar PV systems for PEER Load” IEEE International Conference on Power Electronics, Smart Grid and Renewable Energy (PESGRE-2020), 02-04 Jan 2020, Kochi, pp. 1-6. `,
                    "SCOPUS"]
                ,
                10: ["10. ",
                    `Ahteshamul Haque, K V S Bharath, Mohammed Ali Khan, “ Energy Management Strategy for grid connected solar powered electric vehicle charging station” IEEE International Conference on Transportation Electrification (iTEC -2019), 17-19 Dec 2019, Bangalore, pp. no. 1-6.`,
                    "SCOPUS"]
                ,
                11: [
                    "11. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Machine Learning based islanding detection for grid connected photovoltaic systems” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6`,
                    "SCOPUS"
                ],
                12: [
                    "12. ",
                    `Mohd Sajid Khan, Ahteshamul Haque, K V S Bharath, “Real time Solar Inverter Parameter monitoring for Photovoltaic Systems” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                13: [
                    "13. ",
                    `K V S Bharath, Ahteshamul Haque, Mohammed Ali Khan, A K Tripathy, “ Fault Classification with robust knowledge transfer for single phase grid connected Photovoltaic Systems” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                14: [
                    "14. ",
                    `Mayank Jha, Naman Garg, Fasleen Haider, Asif Raza, Ahteshamul Haque, “Converter control of Hybrid Electric Vehicle” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ]
                ,
                15: [
                    "15. ",
                    `Himanshu Sharma, Ahteshamul Haque, Z A Jaffery, “Research Issues in Energy Harvesting Internet of Things” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ]
                ,
                16: [
                    "16. ",
                    `Himanshu Sharma, Ahteshamul Haque, Z A Jaffery, “Design & Analysis of PWM & MPPT Power Converters forEnergy Harvesting IoT Nodes” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6`,
                    "SCOPUS"
                ],
                17: [
                    "17. ",
                    `Kanula Dadhich, Ahteshamul Haque, K V S Bharath, M Ali Khan, “Fault Identification algorithm for grid connected photovoltaic system using Machine Learning technique” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                18: [
                    "18. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Droop based low voltage ride through implementation for grid integrated photovoltaic system” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                19: [
                    "19. ",
                    `K V S Bharath, Ahteshamul Haque, Mohammed Ali Khan, Arun K Tripathy, “Reliability Analysis of Silicon Carbide Power modules in voltage source converters” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                20: [
                    "20. ",
                    `Irshad, Z A Jaffery, Ahteshamul Haque, A K Dubey, Mohammed Ali Khan, K V S Bharath, “Thermography based real time intelligent condition monitoring system for Solar Power Inverter” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                21: [
                    "21. ",
                    `Al Zoya Fatama, Mohammed Ali Khan, Ahteshamul Haque, K V S Bharath, “Hybrid Algorithm for reactive power control in grid integrated photovoltaic inverters” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                22: [
                    "22. ",
                    `Mohammad Jasim Usmani, Ahteshamul Haque, M Ali Khan, K V S Bharath, “Power management for hybrid energy storage system in electric vehicle” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ],
                23: [
                    "23. ",
                    `Sajjad Ali, Mohd Sajid Khan, M Ali Khan, Ahteshamul Haque, K V S Bharath, “Failure rate basics for a case study on grid connected photovoltaic plant” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ], 24: [
                    "24. ",
                    `Mohd Shahzad, Ahteshamul Haque , KVS Bharath, M Ali Khan, “Review on reliability of power electronic components in photovoltaic inverters” IEEE International Conference on Power Electronics, Control & Automation (ICPECA-2019), 16-17 Nov 2019, New Delhi, pp. 1-6.`,
                    "SCOPUS"
                ], 25: [
                    "25. ",
                    `K V S Bharath, Ahteshamul Haque, A K Tripathi, M A Khan, “Fault Classification for Photovoltaic modules using Thermography and Image Processing” IEEE IAS Annual Meet, Blatimore, Maryland, Oct 2019.`,
                    "SCOPUS"
                ], 26: [
                    "26. ",
                    `M A Khan, Ahteshamul Haque, K V S Bharath , “Enhancement of Fault ride through strategy for single-phase grid-connected photovoltaic systems” IEEE IAS Annual Meet, Blatimore, Maryland, Oct 2019.`,
                    "SCOPUS"
                ], 27: [
                    "27. ",
                    `Kanula Dadheech, Ahteshamul Haque, “Neural Network Approach for Fault Classification on Single-Phase Standalone Photovoltaic Systems.”, IEEE International Conference on Enterpreneurship, Innovation and Leadership, ICEIL- Dec 2018, Noida, India.`,
                    "SCOPUS"
                ],
                28: [
                    "28. ",
                    `Mohd Sajid Khan, Himanshu Sharma, Ahteshamul Haque, “IoT Enabled Real Time Energy Monitoring for Photovoltaic systems” IEEE International Conference on Machine Learning, Big Data, Clod and Parallel Computing COMITCON-2019, Feb 2019,New Delhi`,
                    "SCOPUS"
                ], 29: [
                    "29. ",
                    `Al Zoya Fatama, Ahteshamul Haque, Mohammed Ali Khan, “A Multi Feature Based Islanding Classification Technique for Distributed Generation Systems”, IEEE International Conference on Machine Learning, Big Data, Clod and Parallel Computing COMITCON-2019, Feb 2019,New Delhi.`,
                    "SCOPUS"
                ], 30: [
                    "30. ",
                    `Gaurav Singh, Vikas Verma, Shabana Urooj, Ahteshamul Haque, “Regulation of DC Bus Voltage for DC MicroGrid Using PSIM” 2018 5th IEEE Uttar Pradesh Section International Conference on Electrical, Electronics and Computer Engineering (UPCON).`,
                    "SCOPUS"
                ], 31: [
                    "31. ",
                    `K V S Bharath, Ahteshamul Haque, Mohammed Ali Khan, Arun Kumar Tripathi, “Fault classification for Photovoltaic Modules Using Thermography and Machine Learning Techniques” IEEE International Conference on Computer and Information Sciences (ICCIS), 3-4 April , 2019, Aljouf University Saudi Arabia`,
                    "SCOPUS"
                ],
                32: [
                    "32. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, KVS Bharath, “An Efficient Islanding Classification Technique for Single Phase Grid Connected Photovoltaic System” IEEE International Conference on Computer and Information Sciences (ICCIS), 3-4 April , 2019, Aljouf University Saudi Arabia.`,
                    "SCOPUS"
                ], 33: [
                    "33. ",
                    `Sameen Ahmad, Nabeel Hasan, K. V. S. Bharath, Mohammed Ali Khan, Ahteshamul Haque, “Fault Classification for Single Phase Photovoltaic Systems using Machine Learning Techniques” 8th IEEE India International Conference on Power Electronics, (IICPE 2018) October 2018, MNIT Jaipur.`,
                    "SCOPUS"
                ], 34: [
                    "34. ",
                    `K.V. S. Bharath, Ahteshamul Haque, Mohammed Ali Khan, “Condition Monitoring of Photovoltaic Systems Using Machine Learning Techniques” 2nd IEEE International Conference on Power Electronics, Intelligent Control and Energy systems, (ICPEICES 2018) For publication in conference proceedings on IEEE Xplore.,Accepted: 23rd August 2018.`,
                    "SCOPUS"
                ],
                35: [
                    "35. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, K.V. S. Bharath, “Hybrid Voltage Control for Stand Alone Transformerless Inverter” , 2nd IEEE International Conference on Power Electronics, Intelligent Control and Energy systems, (ICPEICES 2018) August 2018. `,
                    "SCOPUS"
                ], 36: [
                    "36. ",
                    `K. V. S. Bharath, Ahteshamul Haque, Mohammed Ali Khan, “Fault Detection in Single Phase Inverters Using Wavelet Transform based Feature Extraction and Classification Techniques” International Conference on Manufacturing Advance Computing, Renewable Energy and Communication, (MARC 2018) for publication in Book Series of Springer “Lecture Notes in Electrical Engineering” (LNEE, Indexed in Scopus, EI compendex) June 2018`,
                    "SCOPUS"
                ], 37: [
                    "37. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, K.V. S. Bharath, “Voltage Balancing Control for stand-alone H5 Transformerless Inverters” International Conference on Manufacturing Advance Computing, Renewable Energy and Communication, (MARC 2018) For publication in Book Series of Springer “Lecture Notes in Electrical Engineering” (LNEE, Indexed in Scopus, EI compendex) June 2018`,
                    "SCOPUS"
                ], 38: [
                    "38. ",
                    `Mohammed Ali Khan, Ahteshamul Haque, K.V. S. Bharath, “Control and Stability Analysis of H5 Transformerless Inverter Topology”, IEEE International Conference on Computing, Power and Communication Technologies 2018 (GUCON) May 2018. `,
                    "SCOPUS"
                ], 39: [
                    "39. ",
                    `Ahteshamul Haque, Zaheeruddin, “Research on Solar Photovoltaic (PV) Energy Conversion System: An Overview”, IET Conference on Power Control and Instrumentation, PCIE- 2013. `,
                    "SCOPUS"
                ], 40: [
                    "40. ",
                    `Ahteshamul Haque. Rahul Sharma, “Simulation and Analysis of Electric control system for metal halide high intensity discharge lamps.” International conference on Recent Trend in Power Electronics and instrumentation Engineering PEIE-2014, Vol.2, pp.no 144-151 `,
                    "Google Scholar"
                ],
                41: [
                    "41. ",
                    `Ahteshamul Haque, Rahul Sharma, “Simulation and Analysis of Power Factor Correction in Electric Control System for Metal Halide HID Lamps.” International Conference on Advances in Electrical and Electronic Engineering, Vol.4, No.2,pp.no 185-192,2014, ISBN No. 2331-1297`,
                    "SCOPUS"
                ], 42: [
                    "42. ",
                    `Ahteshamul Haque, Rahul Sharma, “Design of optimum controller for electronic control system of Metal Halide-High Intensity Discharge Lamps,” IEEE Conference on Engineering and Systems, May 2014. `,
                    "SCOPUS"
                ], 43: [
                    "43. ",
                    `Ahteshamul Haque, “Evaluation of Operational Characteristics of Electronic Ballasts for Metal Halide HID Lamps”, in Proc. IEEE PEDS, 2006, pp. 1-7. `,
                    "SCOPUS"
                ], 44: [
                    "44. ",
                    `Ahteshamul Haque, “An Overview of DC Microgrid- A Component of Smart Cities” In  Proceedings of 5th National Summit on Smart Cities, PHD Chamber of Commerce and Industries, New Delhi, Dec 2015.`,
                    "Proceedings"
                ], 45: [
                    "45. ",
                    `Sachin K Singh, Ahteshamul Haque, “Simulation and Analysis of Interleaved Boost DC-DC Converter”, National Conference on Emerging Trends in Electrical and Electronics Engineering, Vol. 1, 2015, New Delhi. ISBN: 978-93-84869-20-5.`,
                    "Proceedings"
                ], 46: [
                    "46. ",
                    `A. Rafiq, A. Sameen. M. Shafaq, H. Parveen, A. Haque, “A Reliable and low cost control circuit of Electronic Ballast for Metal Halide HID lamps” IEEE International Conference INDICON Dec 2015.`,
                    "SCOPUS"
                ], 47: [
                    "47. ",
                    `Sachin K Singh, Ahteshamul Haque, “Performance Evaluation of MPPT using Boost converters for solar Photovoltaic System.” IEEE International Conference INDICON Dec 2015.`,
                    "SCOPUS"
                ], 48: [
                    "48. ",
                    `Ahteshamul Haque, N Khan, Javed Khan, F Mahboob, A Siraj, “ A simple and Efficient Control of Single Phase Solar Inverter”, IEEE Conference on ICPEDC 2017, Chennai. `,
                    "SCOPUS"
                ], 49: [
                    "49. ",
                    `R Rais, S Urooj, Ahteshamul Haque, “Performance Evaluation of DC Microgrid using Solar PV Module” Springer 4th International Conference on Information System design and Intelligent Applications, May 2017.`,
                    "SCOPUS"
                ], 50: [
                    "50. ",
                    `K Kamal, K Singh, S Urooj, Ahteshamul Haque, “ Three Phase PLLs for utility Grid interfaced Inverters using PSIM” Springer 4th International Conference on Information System design and Intelligent Applications, May 2017. `,
                    "SCOPUS"
                ], 51: [
                    "51. ",
                    `Himanshu Sharma, Ahteshamul Haque, Z A Jaffery, “ Design challenges in Solar Energy Harvesting for wireless sensor network” Proceedings of IEEE International Conference NANOFIM 2017. `,
                    "Proceedings"
                ],
                52: [
                    "52. ",
                    `M Ali Khan, Ahteshamul Haque, “Performance Analysis of HERIC Topology used in Transformerless inverter” Proceedings of  IEEE International Conference NANOFIM 2017`,
                    "Proceedings"
                ], 53: [
                    "53. ",
                    `M Ali Khan, Ahteshamul Haque, “Performance Analysis of H5 Topology of Transformerless inverter” Proceedings of  IEEE International Conference NANOFIM 2017`,
                    "Proceedings"
                ], 54: [
                    "54. ",
                    `Azra Malik, Ahteshamul Haque, “ A Novel Transformerless Inverter topology for grid connected PV Systems” Proceedings of  IEEE International Conference NANOFIM 2017`,
                    "Proceedings"
                ], 55: [
                    "55. ",
                    `Sheena Siddiqui, Ahteshamul Haque, “Role of Inverters in providing reactive power support to the grid” Proceedings of  IEEE International Conference NANOFIM 2017`,
                    "Proceedings"
                ], 56: [
                    "56. ",
                    `Ahteshamul Haque, Sameen Ahmad, Hasan Nabeel, Soma Perveen, “ Single phase Transformerless inverters for solar photovoltaic systems: A Review” Proceedings of  IEEE International Conference NANOFIM 2017`,
                    "Proceedings"
                ], 57: [
                    "57. ",
                    `Himanshu Sharma, Ahteshamul Haque, Zainul Abdin Jaffery, “Design Challenges in Solar Energy Harvesting Wireless Sensor Networks” Nanotechnology for Instrumentation and Measurement (NANOFIM) Workshop, 3rd  International Conference, Gautam Budh University (GBU), Greater Noida, pp. 442-448, November 16, 2017. `,
                    "Proceedings"
                ], 58: [
                    "58. ",
                    `Himanshu Sharma, Ahteshamul Haque, Zainul Abdin Jaffery, “An efficient Solar Energy Harvesting System for WSN nodes”, 2nd IEEE conference on Power electronics, Intelligent control and Energy Systems, Delhi Technological University (DTU),  Delhi, 27 Oct. 2018.`,
                    "SCOPUS"
                ], 59: [
                    "59. ",
                    `Himanshu Sharma, Chetan Sharma, Madhav Sharma,  Ahteshamul Haque, Zainul Abdin Jaffery, “Performance Analysis of Solar Powered DC-DC Buck Converter for Energy Harvesting IoT nodes”,  IEEE International conference on power electronics and its impact on Humanity, KIET Ghaziabad, CIPECH, 1 Nov. 2018.`,
                    "SCOPUS"
                ]

            }
        }
    },

]

export {
    publicationList
}