import React from "react";

import "../styles/footer.css";
import "../App.css";

const footer = () => {
  return (
    <div className="section footer">
      <p>Contact Us</p>

      <div className="footer-container">
        <div className="address">
          <p>Room No. 127 </p>
          <p>Advance Power Electronics Research Lab</p>
          <p>Department of Electrical Engineering</p>
          <p>
            Jamia Millia Islamia (A Central University)
          </p>
          <p>Jamia Nagar, New Delhi - 110025{" "}</p> 

          <p>
            <strong>Contact:</strong> +91-11-26981717-Ext-2359
          </p>
          <p> ahaque@jmi.ac.in</p>
        </div>

        <div className="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d56068.12181650936!2d77.282715!3d28.562026!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb09de6f2e8596e7!2sJamia%20Millia%20Islamia!5e0!3m2!1sen!2sin!4v1588541938716!5m2!1sen!2sin"></iframe>
        </div>
      </div>
    </div>
  );
};

export default footer;
