import React from 'react';
import Table from 'react-bootstrap/Table'

import { InstrumentsList } from "../constants"

const images = require.context("../images", true);


function instruments(props) {
    return (
        <div>
            <div style ={{
                width: '90vw',
                padding: "1rem"
            }}>
                <Table responsive="lg" striped bordered hover>
                    {InstrumentsList.map((item, index) => {
                        const imgsrc = item.image ? images(`./${item.image}`) : ""
                        return (
                            <><thead>
                                <tr>
                                    <th>{index + 1}. {item.title}</th>
                                    <th>
                                        {
                                            imgsrc ?
                                                <img
                                                    src={imgsrc}
                                                    width="150px"
                                                    height="150px"
                                                    alt="">
                                                </img> :
                                                null
                                        }

                                    </th>

                                </tr>
                            </thead>
                                <tbody striped>
                                    <td>
                                        <ul style={{
                                            padding: "1rem"
                                        }}>
                                            {
                                                item.chars.map((elem) => {
                                                    return (
                                                        <>
                                                            <li>{elem}</li>
                                                        </>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </td>


                                </tbody></>
                        )
                    })}


                </Table>
            </div>
        </div>
    );
}

export default instruments;