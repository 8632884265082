import React from "react";

import "../styles/header.css";

import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import home from "../images/home.svg";
import flag from "../images/flag.png";
import Hamburger from "../images/burger.svg";
import Close from "../images/close.svg";

const NavLinks = ({ open, onClickToggle }) => {
  return (
    <div
      className="mobile-nav-links"
      style={{
        transform: `translateY(${open ? "4rem" : "-10rem"})`,
        top: "0rem",
        display: open ? "" : "none"
      }}
      onClick={onClickToggle}
    >
      <ul>
        <li>
          <Link className="nav-link" to="/research">
            Current Research
          </Link>
        </li>

        <li>
          <Link className="nav-link" to="/publications">
            Publications
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/project">
            R&D Project
          </Link>
        </li>

        <li>
          <Link className="nav-link" to="/facilities">
            Research Facilities
          </Link>
        </li>

        <li>
          <Link className="nav-link" to="/awards">
            Awards
          </Link>
        </li>

        <li>
          <Link className="nav-link" to="/media">
            Media
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/students">
            Alumni
          </Link>
        </li>
        <Link className="nav-link" to="/active-students">
             Active Students
         </Link>

      </ul>
    </div>
  );
};

const MobileNav = ({ open, toggleOnClick }) => {
  return (
    <div className="mobile-nav">
      {open ? (
        <img src={Close} onClick={toggleOnClick} />
      ) : (
        <img src={Hamburger} onClick={toggleOnClick} />
      )}
    </div>
  );
};

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, isMobile: isMobile };
  }

  _toggleMobileNav = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { open } = this.state;
    return (
      <>
        <NavLinks open={open} onClickToggle={this._toggleMobileNav}></NavLinks>
        <div className="header-container">
          <div
            style={{
              paddingLeft: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            className="header-sub-container"
          >
            <Link to="/">
              <img src={flag} id="flag" />
              <img src={home} />
            </Link>
          </div>

          {isMobile && (
            <>
              <MobileNav
                open={open}
                toggleOnClick={this._toggleMobileNav}
              ></MobileNav>
            </>
          )}

          {!isMobile && (
            <div className="header-sub-container">
              <ul>
                <li>
                  <Link className="nav-link" to="/research">
                    Current Research
                  </Link>
                </li>

                <li>
                  <Link className="nav-link" to="/publications">
                    Publications
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/project">
                    R&D Project
                  </Link>
                </li>

                <li>
                  <Link className="nav-link" to="/facilities">
                    Research Facilities
                  </Link>
                </li>

                <li>
                  <Link className="nav-link" to="/awards">
                    Awards
                  </Link>
                </li>

                <li>
                  <Link className="nav-link" to="/media">
                    Media
                  </Link>
                </li>

                <li>
                  <Link className="nav-link" to="/students">
                    Alumni
                  </Link>
                </li>

                <li>
                  <Link className="nav-link" to="/active-students">
                    Active Students
                  </Link>
                </li>

              </ul>
            </div>
          )}
        </div>
      </>
    );
  }
}
